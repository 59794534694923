import { IResponseMessage } from "common/Models";
import CauHinhGiaoDienService from "services/CauHinhGiaoDienService";

export const Actions: any = {
  GetSuKien: async (data:any, dispatch: any) => {
    let suKien = {
      TinhTrang: data.SuKien.TinhTrang,
      SoLuong: data.SuKien.SoLuong,
    };
    let res: IResponseMessage = await CauHinhGiaoDienService.GetCauHinhSuKien(
      suKien
    );
    dispatch({
      type: "GetItemSuKien",
      items: res.Data,
      data: data
    });
  },
};
