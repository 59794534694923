export interface IState {
  DataThanhTich: any;
  DataHocVien: any;
}
export const InitState: IState = {
  DataThanhTich: [],
  DataHocVien: {
    TongDiem: 0,
    SoKhoaHoc: 0,
  },
};
