import React from "react";
import { connect } from "react-redux";

interface Props {}

const BannerTop = (props: Props) => {
  return (
    <>
      {/* <section className="banner_2nd d-flex justify-content-center align-items-center mt-5">
        <img
          loading="lazy"
          src={bg12}
          style={{ width: "70vw", height: "auto" }}
          alt="..."
        />
      </section> */}
    </>
  );
};
const mapState = ({ ...state }) => ({});
const mapDispatchToProps = {};

export default connect(mapState, mapDispatchToProps)(BannerTop);
