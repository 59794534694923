export interface IState {
    CartQuantity: Number,
    InfoShop: any,
    SocialMedia: any,
    TrangChuConfig: any 
}
export const InitState: IState = {
    CartQuantity: 0,
    InfoShop: null,
    SocialMedia: null,
    TrangChuConfig: null
};  
