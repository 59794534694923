import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import { Reducer as AppReducer} from './appReducer';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    app: AppReducer
})
const composedEnhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
)
  

const store = createStore(rootReducer, composedEnhancer)
export default store