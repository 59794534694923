import { IResponseMessage } from "common/Models";
import CauHinhGiaoDienService from "services/CauHinhGiaoDienService";

export const Actions: any = {
  GetItemGiaoan: async (data:any, dispatch: any) => {
    let res: IResponseMessage = await CauHinhGiaoDienService.GetCauHinhMonHoc(
      data.MonHoc.DsMaMonHoc
    );
    dispatch({
      type: "GetItemGiaoAn",
      items: res.Data,
      data: data
    });
  },
};
