import { IResponseMessage } from "common/Models";
import CauHinhGiaoDienService from "services/CauHinhGiaoDienService";

export const Actions: any = {
  GetItemKhoaHoc: async (data:any, dispatch: any) => {
    let res: IResponseMessage = await CauHinhGiaoDienService.GetCauHinhKhoaHoc(
      data.KhoaHoc
    );

    dispatch({
      type: "GetItemsKhoaHoc",
      items: res.Data,
    });
  }
};
