import { InitState, IState } from "./InitState";

export const Reducer = (state: IState = InitState, action: any) => {
  switch (action.type) {
    case "GetItemSuKien":
      let dataSuKien = {
        TenTieuDe: action.data.SuKien.TieuDe,
        DanhSachSuKien: [...action.items],
      };
      return {
        ...state,
        DataItemsSuKien: dataSuKien,
      };

    default:
      return state;
  }
};
