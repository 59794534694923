import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { IUserInfo } from "common/Models";
import { Storage } from "common/Storage";
import { env } from "process";

interface Props { }

const BannerTop = (props: Props) => {
  const history = useHistory();
  let userInfo: IUserInfo = JSON.parse(Storage.getSession("UserInfo"));
  const GoToOtherPage = (page: string) => {
    history.push(page);
    window.scrollTo(0, 0);
  };
  return (
    <div className="jo">
      <section className="banner banner_btn_rout">
        <img loading="lazy" src={process.env.ROOT_URL + "StaticFiles/ImagesCache/banner-index.jpg"} className="main_banner clear-banner" alt="..." />

        {!userInfo && (
          <button
            onClick={() => {
              GoToOtherPage("/dang-ky");
            }}
            className="button-49 banner_btn_ri"
            role="button"
          >
            <p>Mua ngay</p>
          </button>
        )}
      </section>
    </div>
  );
};
const mapState = ({ ...state }) => ({});
const mapDispatchToProps = {};

export default connect(mapState, mapDispatchToProps)(BannerTop);
