export interface IState {
  DataItemsSuKien: {
    DanhSachSuKien: [];
    TenTieuDe: string;
  };
}
export const InitState: IState = {
  DataItemsSuKien: {
    DanhSachSuKien: [],
    TenTieuDe: "",
  },
};
